<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Factures</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="invoices"
          placeholder-text-filter="Chercher une facture"
          :display-text-filter="true"
          :display-reset="true"
          @changePage="changePage"
        />

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="invoices"
              :default-actions="false"
              :custom-actions="getActions"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              no-top-border
              hover
              :link="(item) => `/invoices/${item.id}`"
              :empty-img-src="require('@/assets/images/icons/invoice.svg')"
              empty-message="Vous n'avez aucune facture"
              @download="download"
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="mt-3 drawer-footer d-flex justify-content-center align-items-center">
              <pagination state="invoices"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :range="2"
                          :display-per-page="true"
                          @change="changePage"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import userData from '@/mixins/user-data'
import download from '@/mixins/download'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import pagination from '@/mixins/pagination'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [formatsDate, userData, formatsCurrency, download, pagination],
  computed: {
    getActions () {
      return [
        {
          label: 'Télécharger la facture',
          icon: '',
          event: 'download',
          index: 10
        }
      ]
    },
    cols () {
      return [
        {
          header: 'Date',
          property: 'date',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.date)
          },
          isSortable: true
        },
        {
          header: 'Numéro de facture',
          property: 'reference',
          isSortable: true
        },
        {
          header: 'Référence de la commande',
          property: 'order.reference',
          method: (item) => {
            return get(item, 'order.reference', '')
          },
          isSortable: true
        },
        {
          header: 'Client',
          property: 'customer.firstName',
          method: (item) => {
            return `${get(item, 'customer.firstName')} ${get(item, 'customer.lastName')}`
          },
          isSortable: true
        },
        {
          header: 'Montant total',
          property: 'total',
          method: (item) => {
            return this.$money(item.total)
          },
          isSortable: true
        }
      ]
    },
    invoices () {
      return this.paginatedData.data
    }
  },
  methods: {
    async download (item) {
      await this.downloadFile(`/v1/stores/${this.currentStoreId}/invoices/${item.id}/pdf`)
    },
    async changePage (page) {
      await this.fetchProducts(page)
    },
    async fetchProducts (page) {
      await this.$store.dispatch('invoices/fetch', { page, storeId: this.currentStoreId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchProducts()
    }
  },
  async created () {
    this.instance = 'invoices'
    await this.fetchProducts()
  }
}
</script>

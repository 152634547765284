import Vue from 'vue'
import http from '@/utils/http'

export default Vue.extend({
  methods: {
    async downloadFile (path: string, fileName = 'File'): Promise<boolean> {
      try {
        const response = await http.get(path, { responseType: 'blob' })

        const blob = new Blob([response.data], { type: response.data.type })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = response.headers['file-name'] ? response.headers['file-name'] : fileName
        link.click()
        URL.revokeObjectURL(link.href)
        return true
      } catch (error) {
        return false
      }
    }
  }
})
